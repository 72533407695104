<template>
  <div id="detail">
    <div class="part1"></div>
    <div class="part2">
      <div class="Breadcrumb">
        <b-breadcrumb>
          <b-breadcrumb-item href="#/">
            <b-icon icon="house"
                    scale="1.25"
                    shift-v="1.25"
                    aria-hidden="true"></b-icon>
            首页
          </b-breadcrumb-item>
          <b-breadcrumb-item href="#/case">客户案例</b-breadcrumb-item>
          <b-breadcrumb-item active>案例详情</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <div class="title">{{detail.name}}</div>
      <div class="des">
        <div class="des_logo">
          <b-img :src="require('../../assets/img/case/icon.png')"></b-img>
        </div>
        <div class="des_content">
          <div class="des_info">
            {{detail.evaluate}}
          </div>
          <div class="from">——— {{detail.spokesman}}</div>
        </div>
      </div>
    </div>
    <div class="part3">
      <div class="title">{{detail.name}}</div>
      <div class="content"
           v-html="detail.body"></div>
      <div class="title">方案架构</div>
      <div class="case_framework">
        <b-img :src="detail.case_framework"></b-img>
      </div>
    </div>
    <div class="part4">
      <div class="title">客户评价</div>
      <div class="lunbo_fa">

        <swiper :options="swiperOption"
                class="lunbo"
                ref="mySwiper">
          <!-- slides -->
          <swiper-slide v-for="(item,index) in detail.evaluate_imgs"
                        :key="index">
            <img :src="item" />
          </swiper-slide>

        </swiper>
        <div class="swiper-button-prev"
             slot="button-prev"></div>
        <div class="swiper-button-next"
             slot="button-next"></div>
      </div>
      <b-button pill
                variant="primary">
        <div class="btn_text1"
             @click="toTry">免费试用</div>
        <div class="btn_text2">{{num}}人已试用</div>
      </b-button>
    </div>
    <myhead :background="'transparent'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
export default {
  name: "caseDetail",
  data () {
    return {
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        slidesPerView: 3,
        spaceBetween: 20,
        // autoplay: 2000,
        // speed: 300,
      },
      id: this.$route.query ? this.$route.query.id : '',
      detail: {},
      num: ""
    }
  },
  components: {
    foot,
    right,
    myhead
  },
  mounted () {
    this.onResize()

    window.addEventListener("resize", this.onResize)
    this.handleDetail()
    this.peopleNum()
  },
  destroyed () {
    window.removeEventListener("resize", this.onResize)
  },
  methods: {
    toTry () {
      this.$router.push({ name: "Try" })
    },
    onResize () {

      this.screenWidth = document.body.clientWidth
      if (this.screenWidth < 576) {
        this.swiperOption.slidesPerView = 1
      } else {
        this.swiperOption.slidesPerView = 3
      }
    },
    handleDetail () {
      this.$axios.get(`${this.$Tools.Conts.domain}official/successful-cases/${this.id}`).then(res => {
        if (res.data.code === 0) {
          this.detail = res.data.data
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }
      })
    },
    peopleNum () {
      let params = {
        for_count: 1
      }
      this.$axios.get(`${this.$Tools.Conts.domain}official/trials`, { params }).then(res => {
        if (res.data.code === 0) {
          this.num = res.data.data
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
#detail {
  /deep/ .swiper-wrapper {
    align-items: center;
  }
  background: rgb(247, 247, 247);
  .part1 {
    background: url("../../assets/img/case/bac_detail1.png") no-repeat top
      center;
    background-size: 135%;
    height: 18rem;
  }
  .part2 {
    margin: -13rem auto 0;
    width: 90%;
    padding: 0 0 3rem;
    .Breadcrumb {
      margin: auto;
      .breadcrumb {
        background: transparent;
        padding: 0;
        font-size: 0.8rem;
        .breadcrumb-item.active {
          color: #2b85e4;
        }
        a {
          color: white;
        }
      }
    }
    .title {
      font-size: 1.2rem;
      color: white;
      padding: 0rem 0 2rem;
    }
    .des {
      background: white;
      border-radius: 6px;
      box-shadow: 0 1px 5px #d4d4d4;
      padding: 1.5rem 1rem;
      display: flex;
      .des_logo {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        // box-shadow: 0 1px 5px #d4d4d4;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        flex-shrink: 0;
        margin-right: 1rem;
        img {
          width: 100%;
        }
      }
      .des_content {
        width: 100%;
        font-size: 0.7rem;
        .from {
          color: #999999;
          text-align: right;
          padding-top: 1rem;
        }
      }
    }
  }
  .part3 {
    padding: 2rem 15px;
    margin: auto;
    background: white;
    min-height: 20rem;
    .title {
      font-size: 1.2rem;
      text-align: center;
    }
    .content {
      padding: 2rem 0;
      /deep/ video {
        width: 100%;
      }
      /deep/ p {
        margin-bottom: 0.2rem;
      }
    }
    .case_framework {
      padding: 2rem 0;
      img {
        width: 100%;
      }
    }
  }
  .part4 {
    .title {
      padding: 3rem 0 0;
      font-size: 1.2rem;
      text-align: center;
    }
    .lunbo_fa {
      padding: 1rem 3rem;
      position: relative;
      .lunbo {
        /deep/ .swiper-slide {
          img {
            width: 100%;
          }
        }
      }
    }
    .btn {
      padding: 0.5rem 3rem;
      margin: 1rem auto 2rem;
      display: block;
      box-shadow: 0px 1px 4px #007bff;
      .btn_text1 {
        font-size: 0.9rem;
      }
      .btn_text2 {
        font-size: 0.7rem;
        opacity: 0.7;
      }
    }
  }
}
@media screen and (min-width: 576px) {
  #detail {
    background: rgb(247, 247, 247);
    .part1 {
      background: url("../../assets/img/case/bac_detail.png");
      background-size: cover;
      height: 20rem;
    }
    .part2 {
      margin: -13rem auto 0;
      width: 75%;
      padding: 0 0 3rem;
      .Breadcrumb {
        width: 100%;
        margin: auto;
        .breadcrumb {
          background: transparent;
          padding: 0.75rem 0;
          font-size: 1rem;
          .breadcrumb-item.active {
            color: #2b85e4;
          }
          a {
            color: white;
          }
        }
      }
      .title {
        font-size: 1.5rem;
        color: white;
        padding: 0rem 0 2rem;
      }
      .des {
        background: white;
        border-radius: 6px;
        box-shadow: 0 1px 5px #d4d4d4;
        display: flex;
        padding: 3rem 8rem 2rem 3rem;
        .des_logo {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          // box-shadow: 0 1px 5px #d4d4d4;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          flex-shrink: 0;
          margin-right: 3rem;
          display: flex;
          img {
            width: 100%;
          }
        }
        .des_content {
          width: 100%;
          font-size: 1.1rem;
          .from {
            color: #999999;
            text-align: right;
            padding-top: 1rem;
          }
        }
      }
    }
    .part3 {
      padding: 2rem 5rem;
      margin: auto;
      background: white;
      min-height: 20rem;
      .title {
        font-size: 1.2rem;
        text-align: center;
      }
      .content {
        max-width: 1200px;
        padding: 2rem 0;
        margin: auto;
        /deep/ video {
          width: 100%;
        }
        /deep/ p {
          margin-bottom: 0.2rem;
        }
      }
      .case_framework {
        max-width: 1200px;
        padding: 2rem 0;
        margin: auto;
        img {
          width: 100%;
        }
      }
    }
    .part4 {
      .title {
        padding: 5rem 0 0;
        font-size: 1.8rem;
        text-align: center;
      }
      .lunbo_fa {
        padding: 3rem 18rem;
        position: relative;
        .lunbo {
          padding: 1rem 0rem;
          position: relative;
          /deep/ .swiper-slide {
            transition: all 0.3s;
            img {
              width: 100%;
            }
            // &:not(.swiper-slide-next) {
            //   transform: scale(0.8);
            //   opacity: 0.5;
            // }
          }
        }
        /deep/ .swiper-button-prev {
          left: 10rem;
        }
        /deep/ .swiper-button-next {
          right: 10rem;
        }
      }
      .btn {
        padding: 0.5rem 3rem;
        margin: 1rem auto 2rem;
        display: block;
        box-shadow: 0px 1px 4px #007bff;
        .btn_text1 {
          font-size: 0.9rem;
        }
        .btn_text2 {
          font-size: 0.7rem;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>